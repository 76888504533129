import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

export const setAddUser = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/users/add-user`, {
            create_dt: date,
            update_dt: date,
            username: data.get('username'),
            password: data.get('password'),
            urole: data.get('urole'),
        });
        return response;
    } catch (err) {

        return err;

    }
}