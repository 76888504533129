import dayjs from 'dayjs';
import Axios from 'axios';
import { API_URL } from '../../App';

const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

export const setChangePassword = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/change-password`, {
            update_dt: date,
            current_password: data.get('current_password'),
            new_password: data.get('new_password'),
        });

        return response;

    } catch (err) {

        return err;

    }
}