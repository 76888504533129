import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  CustomSelectFiled,
  CustomFormGroup,
  CustomInputField,
} from "../../Components/Common/Common";
import { UserRole } from "./../../Data/Data";
import { setAddUser } from "./ApiCalls";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

const AddUsers = () => {
  const [selectedUser, setSelectedUser] = useState(null);

  const handleChangePtype = (event) => {
    setSelectedUser(event.value);
  };

  const handleAddUser = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setAddUser(data)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Breadcrumb PageName="Add Users" />
      <Box component="form" autoComplete="off" onSubmit={handleAddUser}>
        <Box className="custom-card">
          <Typography variant="h4" className="custom-card-head">
            Users Details :-
          </Typography>
          <Box p={2}>
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="Username"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="username"
                      placeholder="Enter Username ..."
                      required={true}
                    />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="Password"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="password"
                      name="password"
                      required={true}
                      placeholder="Enter Password ..."
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="User Role"
                  FormField={
                    <CustomSelectFiled
                      name="urole"
                      options={UserRole}
                      onChange={handleChangePtype}
                    />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                {/* <CustomFormGroup
                  formlabel="Property Type"
                  FormField={
                    <CustomSelectFiled
                      name="ptype"
                      options={PropertyType}
                      onChange={handleChangePtype}
                    />
                  }
                /> */}
              </Grid>
            </Grid>

            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={12} sx={{ textAlign: "center" }}>
                <Button variant="contained" type="submit">
                  Add User
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddUsers;
