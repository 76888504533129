import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HomeIcon from '@mui/icons-material/Home';
import "./Breadcrumb.css";

const Breadcrumb = (props) => {
  return (
    <Grid container sx={{ p: 1 }} >
      <Grid item xs={12}>
        <Typography sx={{ pb: 0.5 }} variant="h6" className="hometext">
          <HomeIcon />
          <span className="separator">/</span>
          {props.PageName}
        </Typography>
        <Typography variant="h2" className="page-title">
          {props.PageName}
        </Typography>
        {/* <Typography variant="h5" className="page-subtitle">
          {props.PageDesc}
        </Typography> */}
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
