import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Country, State, City } from "../../Data/CountryStateCity";
import {
  CustomInputField,
  CustomFormGroup,
  CustomMobileFiled,
  CustomSelectFiled,
} from "../../Components/Common/Common";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { setAddBroker, getAllsmUsers } from "./ApiCalls";

const AddBroker = () => {
  const navigate = useNavigate();
  const [Error, setError] = useState("");
  const [Success, setSuccess] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  function filterCity(selectedState) {
    let myCity = City.filter(
      (City) => selectedState.countryCode.indexOf(City.countryCode) !== -1
    );
    return myCity.filter(
      (citynew) => selectedState.isoCode.indexOf(citynew.stateCode) !== -1
    );
  }

  const AllSalesManager = useQuery("AllSalesManager", getAllsmUsers);

  const handleAddBroker = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (parseInt(data.get("lmobile")) === 0) {
      alert("Please fill out required fields.");
    } else {
      setAddBroker(data)
        .then((response) => {
          if (response.data === "Broker Added Successfully") {
            setError("");
            setSuccess(response.data);
            setTimeout(() => {
              navigate("/all-broker");
            }, 2000);
          } else {
            setSuccess("");
            setError("Something went wrong, please check");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // eslint-disable-next-line
  const [SalesID, setSalesID] = useState();

  const handleChangePtype = (event) => {
    setSalesID(event.currentTarget);
  };

  return (
    <>
      <Breadcrumb PageName="Add Broker" />
      <Box component="form" autoComplete="off" onSubmit={handleAddBroker}>
        <Box className="custom-card">
          <Typography variant="h4" className="custom-card-head">
            Broker Details :-
          </Typography>
          <Box p={2}>
            {Success !== "" && Error === "" ? (
              <Alert severity="info" icon={false} sx={{ mb: 2 }}>
                {Success}
              </Alert>
            ) : null}
            {Error !== "" && Success === "" ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {Error}
              </Alert>
            ) : null}
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="Owner Name"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="owner_name"
                      placeholder="Enter Owner Name ..."
                      required={true}
                    />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="Company Name"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="company_name"
                      required={true}
                      placeholder="Enter Company Name ..."
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomMobileFiled
                  formlabel="Mobile No"
                  star="*"
                  Inputname={["ccode", "mobile"]}
                  InputRequired={true}
                  required={true}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="Email Id"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="email"
                      name="email_id"
                      required={true}
                      placeholder="Enter Email Id ..."
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="MahaRERA"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      required={true}
                      name="maharera"
                      placeholder="Enter MahaRERA No."
                    />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <Grid item md={4} xs={12} sm={12} className="custom-from-group">
                  <Typography variant="h6" className="custom-form-label">
                    Country <span className="required-label">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                  <Select
                    name="country"
                    options={Country}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    required
                    value={selectedCountry}
                    onChange={(item) => {
                      setSelectedCountry(item);
                      setSelectedState([]);
                      setSelectedCity([]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <Grid item xs={12} sm={12} md={4} className="custom-from-group">
                  <Typography variant="h6" className="custom-form-label">
                    State <span className="required-label">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                  <Select
                    name="state"
                    required
                    options={
                      selectedCountry !== null
                        ? State.filter(
                            (State) =>
                              selectedCountry.isoCode.indexOf(
                                State.countryCode
                              ) !== -1
                          )
                        : []
                    }
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedState}
                    onChange={(item) => {
                      setSelectedState(item);
                      setSelectedCity([]);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <Grid item xs={12} sm={12} md={4} className="custom-from-group">
                  <Typography variant="h6" className="custom-form-label">
                    City <span className="required-label">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                  <Select
                    name="city"
                    required
                    options={
                      selectedState !== null && selectedState.length !== 0
                        ? filterCity(selectedState)
                        : []
                    }
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedCity}
                    onChange={(item) => {
                      setSelectedCity(item);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="Locality"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="locality"
                      required={true}
                      placeholder="Enter Locality..."
                    />
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                <CustomFormGroup
                  formlabel="SubLocality"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="sub_locality"
                      required={true}
                      placeholder="Enter SubLocality..."
                    />
                  }
                />
              </Grid>
            </Grid>
            {!AllSalesManager.isLoading ? (
              <Grid container mb={2}>
                <Grid item md={6} xs={12} sm={12} className="custom-from-group">
                  <CustomFormGroup
                    formlabel="Sales ID"
                    star="*"
                    FormField={
                      <CustomSelectFiled
                        name="sales_id"
                        options={AllSalesManager.data}
                        onChange={handleChangePtype}
                      />
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid container spacing={2} my={1}>
              <Grid item xs={6} md={12} sx={{ textAlign: "center" }}>
                <Button variant="contained" type="submit">
                  Add Broker
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddBroker;
