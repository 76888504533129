import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import LeftSideNav from "./Components/LeftSideNav/LeftSideNav";

import {
  Login,
  Dashboard,
  AddBroker,
  EditBroker,
  AllBroker,
  AllUsers,
  AddUsers,
  ChangePassword,
} from "./Pages/Pages";

import axios from "axios";
import "./App.css";

import Box from "@mui/material/Box";

export const API_URL = "https://brokerbackendcrm.rabs.support/";
// export const API_URL = "http://localhost:3002";
axios.defaults.withCredentials = true;

export const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Box sx={{ display: "flex" }}>
        <Router>
          <Routes>
            <Route path="/" element={<LeftSideNav />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/all-broker" element={<AllBroker />} />
              <Route path="/add-broker" element={<AddBroker />} />
              <Route path="/edit-broker/:broker_id" element={<EditBroker />} />
              <Route path="/all-users" element={<AllUsers />} />
              <Route path="/add-user" element={<AddUsers />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </Box>
    </QueryClientProvider>
  );
};

export default App;
