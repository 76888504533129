import dayjs from "dayjs";
import Axios from "axios";
import { API_URL } from "../../App";

const date = dayjs().format("YYYY-MM-DD HH:mm:ss");

export const fetchAllBroker = async () => {
  try {
    const response = await Axios.post(`${API_URL}/broker/all-broker`);
    return response;
  } catch (err) {
    return err;
  }
};


export const setAddBroker = async (data) => {
  const username = data.get("owner_name").toLowerCase().replace(" ", "_");
  try {
    const response = await Axios.post(`${API_URL}/broker/add-broker`, {
      create_dt: date,
      update_dt: date,
      username: username,
      password: "123",
      owner_name: data.get("owner_name"),
      company_name: data.get("company_name"),
      ccode: data.get("ccode"),
      mobile: data.get("mobile"),
      email_id: data.get("email_id"),
      maharera: data.get("maharera"),
      country: data.get("country"),
      state: data.get("state"),
      city: data.get("city"),
      locality: data.get("locality"),
      sub_locality: data.get("sub_locality"),
      sales_id: data.get("sales_id"),
    });
    return response;
  } catch (err) {
    return err;
  }
};
export const setEditBroker = async (data, broker_id) => {
  try {
    const response = await Axios.post(`${API_URL}/broker/edit-broker`, {
      update_dt: date,
      broker_id: broker_id,
      owner_name: data.get("owner_name"),
      owner_name: data.get("owner_name"),
      company_name: data.get("company_name"),
      ccode: data.get("ccode"),
      mobile: data.get("mobile"),
      email_id: data.get("email_id"),
      maharera: data.get("maharera"),
      country: data.get("country"),
      state: data.get("state"),
      city: data.get("city"),
      locality: data.get("locality"),
      sub_locality: data.get("sub_locality"),
      sales_id: data.get("sales_id"),
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const DeleteSingleBroker = async (broker_id) => {
  try {
    const response = await Axios.post(
      `${API_URL}/broker/delete-single-broker`,
      { broker_id: broker_id }
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getAllsmUsers = async () => {
  try {
    const response = await Axios.post(`${API_URL}/users/all-sm-users`, {
      urole: "Sales Manager",
    });
    const TotalReceivedArr = [];
    response.data.map((row) => {
      TotalReceivedArr.push({
        label: row.u_id,
        value: row.u_id,
      });
    });
    return TotalReceivedArr;
  } catch (err) {
    return err;
  }
};

export const GetBrokerDetails = async (broker_id) => {
  try {
    const response = await Axios.post(`${API_URL}/broker/get-broker-details`, {
      broker_id: broker_id,
    });
    return response;
  } catch (err) {
    return err;
  }
};
