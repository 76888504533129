import Axios from 'axios';
import { API_URL } from '../App';

export const logout = async () => {
    try {
        const response = await Axios.get(`${API_URL}/logout`);
        return response;
    } catch (err) {
        return err;
    }
}
export const getLoginCheck = async () => {
    try {
        const response = await Axios.get(`${API_URL}/loginCheck`);
        return response;
    } catch (err) {
        return err;
    }
}