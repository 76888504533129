import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useQuery } from "react-query";
import { setLogin } from './ApiCalls';
import { getLoginCheck } from '../../Auth/AuthenticationRouter'
import Logo from './../../Images/logo.png'

import './Login.css'

const Login = () => {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [Error, setError] = useState("");

    const LoginCheck = useQuery("getLoginCheck", () => {
        return getLoginCheck()
    }, {})

    if (!LoginCheck.isLoading) {
        if (LoginCheck.data.data === 'Session found') {
            navigate('/dashboard');
        }
    }

    console.log(LoginCheck)

    if (LoginCheck.isLoading) {
        return <Alert severity="info" sx={{ mt: 0 }}>Please wait..,</Alert>
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const LoginSubmit = async (event) => {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        setLogin(data)
            .then((response) => {
                console.log(response)
                if (response.data.status === "Login Done") {
                    setError("")
                    Cookies.set('role', response.data.role, { domain: 'brokercrm.rabs.support', path: '/', expires: 365 })
                    Cookies.set('name', response.data.name, { domain: 'brokercrm.rabs.support', path: '/', expires: 365 })
                    navigate('/dashboard');
                } else {
                    setError(response.data)
                }
            })
    }

    return (
        <Box className="login-wrapper">
            <Box className="login-card" component="form" onSubmit={LoginSubmit}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 3 }}>
                    <img src={Logo} width={40} alt='Logo' />
                    <Typography variant="h4" sx={{ marginLeft: "0.6rem", fontSize: "20px", fontWeight: "500" }}> RABS</Typography>
                </Box>
                <Typography className='heading'>Hi, Welcome Back</Typography>
                <Typography sx={{ my: 2, color: "#697586", fontSize: "16px" }}>Enter your credentials to continue</Typography>

                {Error !== "" ? <Alert severity="error">{Error}</Alert> : null}

                <FormControl fullWidth sx={{ my: 2 }} variant="outlined">
                    <InputLabel>Username</InputLabel>
                    <OutlinedInput
                        type="text"
                        label="Password"
                        name='username'
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }} variant="outlined">
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                        name='password'
                    />
                </FormControl>
                <Button fullWidth variant="contained" className='login-btn' type="submit">Login</Button>
            </Box>
        </Box >
    )
}

export default Login