import Axios from "axios";
import { API_URL } from "../../App";

export const getAllBroker = async () => {
  try {
    const response = await Axios.post(`${API_URL}/broker/all-broker`);
    return response;
  } catch (err) {
    return err;
  }
};
