import * as React from "react";
import { NavLink, Outlet, Navigate, useNavigate, Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListSubheader from "@mui/material/ListSubheader";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PeopleIcon from "@mui/icons-material/People";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Fade from "@mui/material/Fade";
import Chip from "@mui/material/Chip";

import Cookies from "js-cookie";
import { useQuery } from "react-query";

import Logo from "./../../Images/logo.png";
import Icon from "./../../Images/logo.png";
import User from "./../../Images/default-user.png";
import { Pages } from "../../Data/Data";
import { getAllBroker } from "./ApiCalls";

import { logout, getLoginCheck } from "../../Auth/AuthenticationRouter";
import "./LeftSideNav.css";

// left navigation
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function LeftSideNav() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [SearchResult, setSearchResult] = React.useState("");

  const logout_User = () => {
    logout()
      .then(() => {
        Cookies.remove("user");
        Cookies.remove("role");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AllBroker = useQuery("AllBrokerList", getAllBroker);

  // left navigation
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // user profile
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="menuId"
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      MenuListProps={{ handleMenuClose }}
      className="dropdown"
      PaperProps={{
        elevation: 0,
        sx: {
          width: "180px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleMenuClose} sx={{ py: 1 }}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        Profile
      </MenuItem>
      <Divider style={{ margin: 0, p: 0 }} />
      <MenuItem sx={{ py: 1 }} onClick={logout_User}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  // notifications
  const [notifyEl, setNotifyEl] = React.useState(null);
  const isNotifyMenuOpen = Boolean(notifyEl);
  const notifyMenuClose = () => {
    setNotifyEl(null);
  };
  const renderNofiyMenu = (
    <Menu
      anchorEl={notifyEl}
      id="notifyMenuId"
      open={isNotifyMenuOpen}
      onClose={notifyMenuClose}
      onClick={notifyMenuClose}
      MenuListProps={{ onMouseLeave: notifyMenuClose }}
      className="dropdown"
      PaperProps={{
        elevation: 0,
        sx: {
          width: "180px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -0.8,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <ListItem button>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Work" secondary="Jan 7, 2014" />
      </ListItem>
    </Menu>
  );

  // open submenu
  const [ShowUser, setShowUser] = React.useState(false);
  const [ShowBroker, setShowBroker] = React.useState(false);

  const handleClickUser = () => {
    setShowUser(!ShowUser);
  };
  const handleClickBroker = () => {
    setShowBroker(!ShowBroker);
  };

  const BrokerSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "All Broker", Link: "/all-broker" },
    { icon: <CheckBoxIcon />, SubMenuName: "Add Broker", Link: "/add-broker" },
  ];
  const UserSubMenu = [
    { icon: <CheckBoxIcon />, SubMenuName: "All Users", Link: "/all-users" },
    { icon: <CheckBoxIcon />, SubMenuName: "Add User", Link: "/add-user" },
  ];

  let SideMenu = [
    {
      icon: <DashboardIcon />,
      MenuName: "Dashboard",
      Link: "/dashboard",
      submenu: "No",
      MapFunction: "",
      setFunction: "",
      onclickFunction: "",
    },
    {
      icon: <ManageAccountsIcon />,
      MenuName: "Broker",
      Link: null,
      submenu: "Yes",
      MapFunction: BrokerSubMenu,
      setFunction: ShowBroker,
      onclickFunction: handleClickBroker,
    },
    {
      icon: <PeopleIcon />,
      MenuName: "User's",
      Link: null,
      submenu: "Yes",
      MapFunction: UserSubMenu,
      setFunction: ShowUser,
      onclickFunction: handleClickUser,
    },
    {
      icon: <DisplaySettingsIcon />,
      MenuName: "Change Password",
      Link: "/change-password",
      submenu: "No",
      MapFunction: "",
      setFunction: "",
      onclickFunction: "",
    },
  ];
  function clearLocalStorage() {
    return localStorage.setItem("tablePageIndex", 0);
  }

  const LoginCheck = useQuery(
    "getLoginCheck",
    () => {
      return getLoginCheck();
    },
    {}
  );

  if (!LoginCheck.isLoading) {
    if (LoginCheck.data.data === "Session not found") {
      navigate("/login");
    }
  }

  if (LoginCheck.isLoading && AllBroker.isLoading) {
    return (
      <Alert severity="info" sx={{ mt: 0 }}>
        Please wait..,
      </Alert>
    );
  }

  // searchbar
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".9rem",
  }));

  const SearchCard = () => {
    return (
      <Box className="search-card-container">
        <List
          className="search-result-list"
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: "10px",
          }}
          component="nav"
          subheader={
            <ListSubheader component="div" className="search-category-heading">
              Page
            </ListSubheader>
          }
        >
          {
            // eslint-disable-next-line
            Pages.filter((message) => {
              if (SearchResult === "") {
                return message;
              } else if (
                message.pagename
                  .toLowerCase()
                  .includes(SearchResult.toLowerCase())
              ) {
                return message;
              }
            }).map((message, id) => (
              <ListItemButton
                key={id}
                component={Link}
                to={message.path}
                onClick={() => setSearchResult("")}
              >
                <ListItemIcon sx={{ display: "contents" }}>
                  {message.icon}
                </ListItemIcon>
                <ListItemText primary={message.pagename} />
              </ListItemButton>
            ))
          }
          <ListSubheader component="div" className="search-category-heading">
            Broker
          </ListSubheader>
          {AllBroker.data.data
            // eslint-disable-next-line
            .filter((message) => {
              if (SearchResult === "") {
                return message;
              } else if (
                message.owner_name
                  .toLowerCase()
                  .includes(SearchResult.toLowerCase())
              ) {
                return message;
              }
            })
            .map((message, id) => (
              <ListItemButton
                key={id}
                component={Link}
                to={message.path}
                onClick={() => setSearchResult("")}
              >
                <ListItemIcon sx={{ display: "contents" }}>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={message.company_name}
                  secondary={message.owner_name}
                />
              </ListItemButton>
            ))}
        </List>
      </Box>
    );
  };

  return window.location.pathname === "" || window.location.pathname === "/" ? (
    <Navigate to="/login" component={NavLink} replace={true} />
  ) : (
    <>
      <CssBaseline />
      <Fade in direction="up" timeout={800}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 3,
                ...(open && { display: "none" }),
              }}
            >
              <img src={Icon} alt="logo" style={{ width: "30px" }} />
              <MenuIcon sx={{ ml: 3 }} />
            </IconButton>
            <input
              type="text"
              className="search-input"
              placeholder="Search ..."
              value={SearchResult}
              onChange={(e) => setSearchResult(e.target.value)}
            />
            <SearchIconWrapper>
              <SearchIcon sx={{ fontSize: "20px", color: "#74788d" }} />
            </SearchIconWrapper>
            <Box sx={{ flexGrow: 1 }} />
            {SearchResult !== "" ? <SearchCard /> : null}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleProfileMenuOpen}
                  size="large"
                  color="inherit"
                  sx={{ ml: 2 }}
                  aria-controls={isMenuOpen ? "menuId" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? "true" : undefined}
                >
                  <img src={User} alt="user" className="userimg" />
                  <span className="title">{Cookies.get("name")}</span>
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "18px", mt: "-2px" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
      </Fade>
      {renderMenu}
      {renderNofiyMenu}
      <Fade in direction="up" timeout={800}>
        <Drawer variant="permanent" open={open} className="leftsidenav">
          <DrawerHeader sx={{ p: 0 }}>
            <ListItem disablePadding sx={{ display: "block" }}>
              {open ? (
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={Logo} alt="logo" style={{ width: "50px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={Cookies.get("name")}
                    secondary={Cookies.get("role")}
                    className="user-details"
                  />
                </ListItemButton>
              ) : null}
            </ListItem>
            <IconButton
              onClick={handleDrawerClose}
              sx={{ position: "absolute", top: "0", right: "0" }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <List>
            {SideMenu.map((Menu, id) => {
              return (
                <ListItem
                  onMouseOver={handleDrawerOpen}
                  key={id}
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => clearLocalStorage()}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={Menu.Link === null ? Menu.onclickFunction : null}
                    component={Menu.Link === null ? "div" : NavLink}
                    to={Menu.Link === null ? "" : Menu.Link}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {Menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={Menu.MenuName}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {open ? (
                      <>
                        {Menu.submenu === "Yes" ? (
                          <>
                            {Menu.setFunction ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <ChevronLeftIcon />
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </ListItemButton>
                  {Menu.submenu === "Yes" ? (
                    <>
                      <Collapse
                        timeout="auto"
                        in={Menu.setFunction}
                        unmountOnExit
                      >
                        {Menu.MapFunction.map((row, subid) => {
                          return (
                            <Fade in key={subid} direction="up" timeout={800}>
                              <div>
                                <List component="div" disablePadding>
                                  <ListItemButton
                                    sx={{ pl: open ? 5 : 2.5 }}
                                    component={NavLink}
                                    to={row.Link}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 1 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {row.icon}
                                    </ListItemIcon>
                                    {open ? (
                                      <>
                                        <ListItemText
                                          primary={row.SubMenuName}
                                        />
                                        {row.lcount ? (
                                          <Chip
                                            label={row.lcount}
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                              position: "absolute",
                                              right: "0.8rem",
                                              borderRadius: "5px",
                                              border: "1px solid #6d768a",
                                              py: 0.2,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : null}
                                  </ListItemButton>
                                </List>
                                <Divider />
                              </div>
                            </Fade>
                          );
                        })}
                      </Collapse>
                    </>
                  ) : null}
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      </Fade>
      <Box component="main" className={"main"}>
        <DrawerHeader className="test" />
        <Outlet />
      </Box>
    </>
  );
}
