import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import { useNavigate } from 'react-router-dom';
import { setChangePassword } from "./ApiCalls"
import {
    CustomInputField,
    CustomFormGroup,
} from "./../../Components/Common/Common";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

import "./ChangePassword.css"

const ChangePassword = () => {
    
    const navigate = useNavigate();
    const [Error, setError] = useState("");
    const [Success, setSuccess] = useState("");

    const handleChangePassword = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get("new_password") === data.get("confirm_password")) {
            setChangePassword(data)
                .then((response) => {
                    if (response.data === "Password Updated Successfully") {
                        setError("")
                        setSuccess(response.data)
                        setTimeout(() => {
                            navigate('/dashboard')
                        }, 2000)
                    } else {
                        setSuccess("")
                        setError("Your current password is incorrect");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setSuccess("")
            setError("New Password and Confirm Password Must Be Same");
        }
    };

    return (
        <>
            <Breadcrumb PageName="Change Password" />
            <Box className="custom-card">
                <Typography variant="h4" className="custom-card-head">
                    Change Password :-
                </Typography>
                <Box component="form" autoComplete="off" onSubmit={handleChangePassword} method="post" style={{ padding: '24px' }}>
                    {Success !== "" && Error === "" ?
                        <Alert severity="info" icon={false} sx={{ mb: 2 }}>{Success}</Alert>
                        : null}
                    {Error !== "" && Success === "" ?
                        <Alert severity="error" sx={{ mb: 2 }}>{Error}</Alert>
                        : null}
                    <Grid container spacing={2} mb={2}>
                        <CustomFormGroup
                            formlabel="Current Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="text"
                                    name="current_password"
                                    placeholder="Enter Current Password..."
                                    required="required"
                                />
                            }
                        />
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <CustomFormGroup
                            formlabel="New Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="password"
                                    name="new_password"
                                    placeholder="Enter New Password"
                                    required="required"
                                />
                            }
                        />
                    </Grid>
                    <Grid container spacing={2} mb={1}>
                        <CustomFormGroup
                            formlabel="Confirm Password"
                            star="*"
                            FormField={
                                <CustomInputField
                                    type="password"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    required="required"
                                />
                            }
                        />
                    </Grid>

                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={6} md={2}></Grid>
                        <Grid item xs={6} md={10}>
                            <Button variant="contained" type="submit" disableElevation>
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </>
    )
}

export default ChangePassword