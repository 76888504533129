import React, { useMemo } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { fetchAllBroker } from "./ApiCalls";
import {useQuery } from 'react-query';
import Alert from "@mui/material/Alert";

function AllBroker() {

  // const AllBrokerData = () => {
  //   return useQuery("AllBroker", fetchAllBroker);
  // };

  const AllBroker = useQuery("AllBroker", () => {
    return fetchAllBroker()
  },{})

  if (AllBroker.isLoading) {
    <Alert severity="info" icon={false}>
      Please Wait ...
    </Alert>;
  }

  // eslint-disable-next-line
  const columns = useMemo(() => [
    {
      Header: "Broker",
      accessor: "broker_id",
    },
    {
      Header: "Create On",
      accessor: "create_dt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
    {
      Header: "Owner Name",
      accessor: "owner_name",
    },
    {
      Header: "Company Name",
      accessor: "company_name",
    },
    {
      Header: "ccode",
      accessor: "ccode",
      show: false,
    },
    {
      Header: "Mobile No",
      accessor: "mobile",
    },
    {
      Header: "Email Id",
      accessor: "email_id",
    },
    {
      Header: "MahaRERA",
      accessor: "maharera",
    },
    {
      Header: "Sales ID",
      accessor: "sales_id",
    },
  ]);

  // const DeleteBroker = () => {
  //   DeleteSingleBroker();
  // };

  return (
    <>
      <Breadcrumb PageName="All Brokers" />
      {!AllBroker.isLoading ? (
        <CustomTable
          data={AllBroker.data.data}
          columns={columns}
          initialState={{ pageSize: 5, pageIndex: 0, hiddenColumns: ["ccode"] }}
          // DltBtn={DeleteSingleBroker}
          page="Broker"
        />
      ) : null}
    </>
  );
}

export default AllBroker;
