import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PreviewIcon from "@mui/icons-material/Preview";
import { useTable, usePagination } from "react-table";
import { DeleteSingleBroker } from "./../../Pages/Broker/ApiCalls";
import "./CustomTable.css";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const Table = (props) => {
  const [open, setOpen] = React.useState(false);
  const [leadId, setLeadId] = React.useState([]);

  const handleClickOpen = (broker_id) => {
    setOpen(true);
    setLeadId(broker_id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DeleteBrkData = async (broker_id) => {
    handleClose();
    DeleteSingleBroker(broker_id)
      .then((response) => {
        if (response.data === "lead deleted") {
          alert("Leads Deleted Successfully");
        }
        // console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Memos
  const data = React.useMemo(() => props.data, [props.data]);
  const columns = React.useMemo(() => props.columns, [props.columns]);
  // eslint-disable-next-line
  const initialState = React.useMemo(() => props.initialState);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    usePagination
  );

  const navigate = useNavigate();

  //change
  return (
    <>
      <Box className="custom-table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        {cell.column.Header === "Create On" ? (
                          <td key={i} {...cell.getCellProps()}>
                            <Tooltip
                              title={dayjs(row.cells[2].value).format(
                                "D MMM YYYY hh:mm"
                              )}
                              sx={{ py: 0 }}
                            >
                              <span>
                                {dayjs(row.cells[2].value).format(
                                  "D MMM YYYY"
                                ) + "..."}
                              </span>
                            </Tooltip>
                          </td>
                        ) : cell.column.Header === "Action" ? (
                          <td key={i} {...cell.getCellProps()}>
                            <Tooltip
                              title="Delete"
                              onClick={() =>
                                handleClickOpen(row.cells[0].value)
                              }
                            >
                              <IconButton aria-label="delete" size="small">
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Edit"
                              onClick={() =>
                                navigate("/edit-broker/" + row.cells[0].value)
                              }
                            >
                              <IconButton aria-label="Edit" size="small">
                                <AutoFixHighIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="View">
                              <IconButton aria-label="View" size="small">
                                <PreviewIcon />
                              </IconButton>
                            </Tooltip>
                          </td>
                        ) : (
                          <td key={i} {...cell.getCellProps()}>
                            <Tooltip title={cell.render("Cell")} sx={{ py: 0 }}>
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            </Tooltip>
                          </td>
                        )}
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>

      <Box className="bg-pagination">
        <Box className="pagination">
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>{" "}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>{" "}
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>{" "}
          {/* <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "} */}
          <select
            className="custom-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[2, 4, 6, 8, 10].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Box>
      </Box>

      {/* delete confirm */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => DeleteBrkData(leadId)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Table;
